import contentLoaded from './vendor/contentloaded.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import menu from './menu.js'
import smoothScroll from './smooth-scroll.js'
import {processLine, processClicks, processItems} from './process.js'
import quizPopup from './quiz-popup.js'
import { initModals } from './modal.js'
import {goalTiles} from './goal-tiles.js'
import accordions from './accordion.js'
import tabs from './tabs.js'
import { cf7Focus } from './cf7.js'

contentLoaded(window, function (e) {
	document.body.classList.add('ready');
	polyfill()
	sliders()
	menu()
	smoothScroll()
	processLine()
	processClicks()
	processItems()
	quizPopup()
	initModals()
	goalTiles()
	accordions()
	cf7Focus()
	tabs()
})
