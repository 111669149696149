import MicroModal from 'micromodal'

const microModalSettings = {
	openTrigger: 'data-mm-target',
	closeTrigger: 'data-mm-dismiss',
	openClass: 'is-open',
	onShow: (modal) => {
		document.body.classList.add('modal-open');


		if(modal.id === "survey-modal"){
			let surveyIframe = document.querySelector('#surveyiframe');

			if(surveyIframe.parentNode.classList.contains('loaded')){
				return;
			}

			surveyIframe.setAttribute('src', surveyIframe.dataset.src);

			surveyIframe.addEventListener('load', function(){
				surveyIframe.parentNode.classList.add('loaded');
			});
		}
	},
	onClose: (modal) => {
		document.body.classList.remove('modal-open');
	},
}

export function initModals() {
	MicroModal.init(microModalSettings)
}
